import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";


interface Props {
  brand: any;
  brands: [];
  editMode: boolean;
  handleEditedData: any;
}

export type Brand = {
  _id: any;
  name: string;
  email: string;
}

function BrandSection({
  brand,
  brands,
  editMode,
  handleEditedData
}: Props) {
  const [brandValue, setBrandValue] = useState<any>("");

  useEffect(() => {
    setBrandValue(brand)
  }, [])

  const handleChange = (event: SelectChangeEvent<any>) => {
    setBrandValue(event.target.value as Brand);
    handleEditedData(
      "brand",
      "brand",
      event.target?.value?.name,
      false
    )
  };

  return (
    <React.Fragment >
      <Fade in={!editMode}>
        <Box
          component="div"
          sx={{
            display: editMode ? "none" : "flex",
            alignItems: "center",
            gap: 2,
            my: 2,
            marginRight: "58px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: "400", fontSize: "1rem" }}
          >
            Clients: {brandValue.name}
          </Typography>
        </Box>
      </Fade>
      <Fade in={editMode}>
        <Box
          component="div"
          sx={{
            display: !editMode ? "none" : "flex",
            alignItems: "center",
            gap: 2,
            my: 2,
          }}
        >
          <FormControl fullWidth sx={{mb:2}}>
            <InputLabel id="brand-label">Brand</InputLabel>
            <Select
              labelId="brand-label"
              id="brand-select"
              label="Brand"
              value={brandValue}
              onChange={handleChange}
            >
              <MenuItem key="None" value=""></MenuItem>
              {brands?.map((item: Brand, index) => (
                <MenuItem key={index} value={item as any}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Fade>
    </React.Fragment>
  );
}


export default BrandSection;
