import cleanTMPUser from "../../Helpers/cleanTMPUser";

const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

export const getUsers = async (token: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };
  const URL = `${process.env.REACT_APP_SERVER_URL}/users/`;
  try {
    const res = await fetch(URL, requestOptions);
    const data = await res.json();
    return data;
  } catch (err) {
    return 400;
  }
};

interface UpdateUserPermissionsProps {
  users: any;
  token: any;
}

export const updateUserPermissions = async ({
  users,
  token,
}: UpdateUserPermissionsProps) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(users),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/permissions`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      let data = await res.json();
      return data;
    });
  } catch (err) {
    return err;
  }
};

interface EditUserProps {
  token: any;
  uid: any;
  tmpUser: any;
}

export const editUser = async ({ token, uid, tmpUser }: EditUserProps) => {
  cleanTMPUser(tmpUser);
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tmpUser),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${uid}`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      let data = await res.json();
      return data;
    });
  } catch (err) {
    return err;
  }
};

interface DeleteUserProps {
  token: any;
  uid: string;
}

export const deleteUser = async ({ token, uid }: DeleteUserProps) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${uid}`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      return res.status;
    });
  } catch (err) {
    return err;
  }
};

export const getUser = async ({ token, uid }: DeleteUserProps) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };

  if (!uid) {
    return;
  }

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${uid}`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      return res.json();
    });
  } catch (err) {
    return err;
  }
};

export interface GetAPIKeyProps {
  token: any;
  uid: string;
  bundleId: string;
}

export const getAPIKey = async ({ token, uid, bundleId }: GetAPIKeyProps) => {
  let body = { user_id: uid, bundle_id: bundleId };

  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/api/key`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      if (res.status === 200) {
        return res.json();
      } else {
        let error = await res.json();
        return { error: error };
      }
    });
  } catch (err) {
    return err;
  }
};

export const regenerateAPIKey = async ({
  token,
  uid,
  bundleId,
}: GetAPIKeyProps) => {
  let body = { user_id: uid, bundle_id: bundleId };

  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/api/key/regenerate`;

  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      if (res.status === 200) {
        return res.json();
      } else {
        let error = await res.json();
        return { error: error };
      }
    });
  } catch (err) {
    return err;
  }
};
